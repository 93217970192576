<script>
import MessageItem from './MessageItem'
import TextArea from './TextArea'
import FileUpload from './FileUpload'
export default {
  components: { MessageItem, TextArea, FileUpload },
  name: 'ModalMessages',
  props: {
    company: {
      type: Object
    },
    position: {
      type: Object
    },
    user: {
      type: Object
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      messages: [],
      writtenMessage: '',
      writeMessage: false,
      files: null,
      allMessages: []
    }
  },
  methods: {
    close () {
      if (window.location.href.indexOf('applications') > 0) {
        this.$emit('close')
      } else {
        this.$store.dispatch('attemptGetUserMessages').then(() => {
          this.$emit('refreshMessagesCandidature')
          this.$emit('refreshUnreadMessagesModal')
          this.$emit('close')
        })
      }
    },
    attemptWriteMessage () {
      this.writeMessage = !this.writeMessage
    },
    attemptSendMessage () {
      const formData = new FormData()

      formData.append('position', this.position.id)
      formData.append('message', this.writtenMessage)
      formData.append('date', new Date())
      formData.append('files', this.files)

      this.$store.dispatch('attemptPutMessage', formData)
        .then(
          this.attemptGetMessages,
          this.writtenMessage = ''
        ).finally(() => {
          this.files = null
          this.close()
        })
    },
    markMessageRead () {
      const msgs = this.allMessages.filter(m => (m.status !== 'opened' && m.isCompany)).map(m => m._id)
      const body = {
        msgs: msgs,
        time: Date.now(),
        status: 'opened',
        channel: 'enlizt-app'
      }
      this.$store.dispatch('attemptPutReadMessage', body)
    },
    attemptGetMessages () {
      this.isReady = false
      this.$store.dispatch('attemptGetThreadMessages', this.position.id).then(
        data => {
          this.allMessages = data.sort((a, b) => new Date(a.date) - new Date(b.date))
          this.markMessageRead()
        }
      ).finally(() => {
        this.$refs.messagesbody.scrollTop = this.$refs.messagesbody.scrollHeight
      })
      this.isReady = true
    },
    setFile (file) {
      this.files = file
    }
  },
  created () {
    this.attemptGetMessages()
  }
}
</script>
<template>
  <v-dialog :value="true" persistent width="700px" max-width="100%">
    <v-card class="modal-messages--container">
      <div class="modal-messages--header">
        <h3>{{ $t(readonly ? 'global:messages.history' : 'global:messages') }}</h3>
        <v-icon class="clickable" color="#454545" @click="close">mdi-close</v-icon>
      </div>
      <div class="modal-message--body-wrapper">
        <div class="modal-message--sender-receiver">
          <p>{{ $t('global:to') }}: <v-avatar size="24px"><v-img :src="company.image" height="24" width="24"></v-img></v-avatar><span>{{ company.name }}</span></p>
          <p>{{ $t('global:position') }} <span>{{ position.name }}</span></p>
        </div>
        <div class="modal-messages--body" :class="{'readonly': readonly}" ref="messagesbody"  v-if="allMessages.length > 0">
          <message-item
            v-for="message in allMessages"
            :key="message._id"
            :left="!message.isCompany"
            :message="message.message"
            :status="message.status"
            :avatar="message.isCompany ? company.image : workspace.avatar"
            :sender="message.isCompany ? company.name : workspace.name"
            :date="formatDate(message.date, 'short', $i18n.locale === 'pt-BR' ? 'pt-BR': 'en-US')"
            :attachments="message.attachments"
          ></message-item>
        </div>
        <div class="modal-messages--body" :class="{'readonly': readonly}" ref="messagesbody"  v-else>
          <div class="empty-content">
            <p>{{ $t('modal.messages:empty') }}</p>
          </div>
        </div>
      </div>
      <template v-if="!readonly">
        <div class="modal-message--input-area"  v-if="writeMessage">
          <label class="modal-message--input-label">{{ $t('global:message') }}</label>
          <text-area  outlined :placeholder="$t('modal.messages:write.here')" v-model.trim="writtenMessage"></text-area>
        </div>
        <div class="modal-message--actions-apply">
          <file-upload v-if="!!writeMessage" @input="file => setFile(file)"></file-upload>
          <v-btn class="btn mr-4" @click="close" outlined :color="getPrimaryColor">{{ $t(writeMessage ? 'global:cancel' : 'global:close') }}</v-btn>
          <v-btn v-if="writeMessage"  :disabled="!!!writtenMessage" :color="getPrimaryColor" :dark="!!writtenMessage" class="btn" @click="attemptSendMessage"><v-icon class="mr-2" color="#fff" size="20">mdi-send</v-icon>{{ $t('global:send') }}</v-btn>
          <v-btn v-if="!writeMessage" class="btn" @click="attemptWriteMessage" dark :color="getPrimaryColor"><v-icon class="mr-2" color="#fff" size="20">mdi-pencil</v-icon>{{ $t('global:write.message') }}</v-btn>
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.modal-messages--container {
  position: relative;
  .modal-messages--header {
    display: flex;
    justify-content: space-between;
    padding: 18px 24px;
    background-color: #FAFAFA;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    h3 {
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
  }
  .modal-message--sender-receiver {
    background: #FAFAFA;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 16px;
    text-align: left;
    margin: 32px 16px 32px 16px;
    p {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #7E7D77;
      margin: 0;
      &:nth-child(1) {
        margin-bottom: 8px;
      }
      .v-avatar {
        margin: 0 8px;
      }
      span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }
    }
  }
  .modal-message--body-wrapper {
    overflow: auto;
    max-height: calc(100vh - 200px);
  }
  .modal-messages--body {
    &.readonly {
      max-height: calc(100vh - 400px);
    }

    .empty-content{
      padding: 30px 0px 50px 0px;
    }

  }
  .modal-message--input-area {
    padding: 0 16px;
    margin-top: 16px;
    text-align: left;
    .modal-message--input-label {
      margin-bottom: 12px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }
  }
  .modal-message--actions-apply {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 0 24px;
    width: 100%;
    height: 68px;
    background: #FAFAFA;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    .btn.v-btn.v-size--default {
      text-transform: none;
      height: 36px;
      font-size: 14px;
    }
    .file-upload--container {
      margin-right: auto;
    }
  }
}
</style>
